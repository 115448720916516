import React from 'react';
import PropTypes from 'prop-types';
import sectionStyles from './section.module.scss';

const handleTypeError = () => {
  throw new Error(
    'The type prop passed to Section must be one of the string default, sidePaddingOnly, or noPadding'
  );
};

const allowedSectionTypes = ['default', 'sidePaddingOnly', 'noPadding'];

const Section = ({ type, backgroundColor, children }) => {
  if (!allowedSectionTypes.includes(type)) return handleTypeError();

  const sectionWrapperClass = sectionStyles[type];

  return (
    <section
      className={sectionWrapperClass}
      style={{
        overflow: 'hidden',
        position: 'relative',
        zIndex: '1',
        backgroundColor,
      }}
    >
      <div className={sectionStyles.container}>{children}</div>
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  type: PropTypes.string,
};

Section.defaultProps = {
  type: 'default',
};

export default Section;
