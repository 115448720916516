import React from 'react';
import PropTypes from 'prop-types';
import buttonStyles from '../button/button.module.scss';

const ButtonGroup = ({ children }) => <div className={buttonStyles.buttonGroup}>{children}</div>;

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ButtonGroup;
