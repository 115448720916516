import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import head from 'lodash.head';
import classnames from 'classnames';

import SideMenu from './sidemenu';
import MainHeader from '../main-header';
import layoutStyles from './layout.module.scss';
import CookieConsent from '../cookie-consent';

class Layout extends React.Component {
  state = {
    sidemenuIsActive: false,
  };

  toggleSideMenu = () => this.setState({ sidemenuIsActive: !this.state.sidemenuIsActive });

  /**
   * extractInternalLinksFromPages
   *
   * @description
   * Takes an object containing all navigation links and reduces it down to just the
   * internal links to pass to the sidemenu
   * @param {Array} pages The array containing the main nav links configured in the CMS
   * @returns {Array}
   */
  extractInternalLinksFromPages = pages =>
    pages.reduce(
      (accumulatedPages, currentPage) =>
        currentPage.isMainCta ? accumulatedPages : [...accumulatedPages, currentPage],
      []
    );

  componentDidMount() {
    /**
     * Unfortunately needed
     * Gatsby does some react routing without page reload (Awesome!)
     * In this case if you are coming from the homepage, the forced body height (from skrollr plugin) does still apply
     * We have to make sure, that on any other page – on which the height is not specififally set — we use a relative value
     */
    document.body.style.height = '100%';

    if (window.localStorage.getItem('cookiesAllowed')) {
      if (!window.ga) {
        ReactGA.initialize('UA-131771879-1');
      }
      ReactGA.pageview(window.location.pathname);
    }
  }

  render() {
    const {
      navigationBackgroundColor,
      seoTitle,
      seoDescription,
      seoKeywords,
      children,
    } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query pageQuery {
            allContentfulNavigationBar {
              edges {
                node {
                  linkColor
                  logoAltText
                  links {
                    id
                    displayName
                    slug
                    isMainCta
                    externalUrl
                  }
                  logo {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ allContentfulNavigationBar: { edges } }) => {
          const { links, logo, logoAltText, linkColor } = head(edges).node;
          const { sidemenuIsActive } = this.state;
          return (
            <>
              <Helmet
                title={seoTitle}
                meta={[
                  { name: 'description', content: seoDescription },
                  { name: 'keywords', content: seoKeywords },
                ]}
              >
                <html lang="en" />
              </Helmet>
              <CookieConsent />
              <div
                className={classnames(layoutStyles.pageWrapper, {
                  [`${layoutStyles.pageWrapperSidemenuIsActive}`]: sidemenuIsActive,
                })}
              >
                <MainHeader
                  pages={links}
                  logo={logo}
                  backgroundColor={navigationBackgroundColor}
                  linkColor={linkColor}
                  logoAltText={logoAltText}
                  onToggle={this.toggleSideMenu}
                  isActive={sidemenuIsActive}
                />
                <div className={layoutStyles.container}>{children}</div>
              </div>
              <SideMenu
                pages={this.extractInternalLinksFromPages(links)}
                linkColor={linkColor}
                isActive={sidemenuIsActive}
              />
            </>
          );
        }}
      />
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  navigationBackgroundColor: PropTypes.string.isRequired,
  seoTitle: PropTypes.string.isRequired,
  seoDescription: PropTypes.string.isRequired,
  seoKeywords: PropTypes.string.isRequired,
};

export default Layout;
