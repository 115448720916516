import React from 'react';
import PropTypes from 'prop-types';
import head from 'lodash.head';
import buttonStyles from '../button/button.module.scss';

const handleTypeError = () => {
  throw new Error(
    'The type prop passed to ButtonSubmit must be one of the string primary or the string secondary'
  );
};

/**
 * allowedButtonTypes
 *
 * @description
 * Maps over the style sheet key and filters for every classname that ends with `CallToAction`
 * It then maps these classnames, taking only the portion of the classname before `CallToAction`
 * to an array of allowed types to pass to the button's type prop.
 * So for example if the stylesheet has styles for primaryCallToAction and secondaryCallToAction then
 * allowed types are ['primary', 'secondary]. TO add new types just add new classes to the stylesheet
 * @returns {Array}
 */
const allowedButtonTypes = Object.keys(buttonStyles)
  .filter(style => style.includes(buttonStyles.callToActionPrefix))
  .map(style => head(style.match(/[a-z]+(?=CallToAction)/)));

const ButtonSubmit = ({ type, children, backgroundColor, color }) => {
  if (!allowedButtonTypes.includes(type)) return handleTypeError();

  const buttonClass = buttonStyles[`${type}${buttonStyles.callToActionPrefix}`];

  return (
    <button type="submit" className={buttonClass} style={{ backgroundColor, color }}>
      <span className={buttonStyles.buttonContent}>{children}</span>
    </button>
  );
};

ButtonSubmit.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string.isRequired,
};

ButtonSubmit.defaultProps = {
  children: null,
  type: 'primary',
  backgroundColor: null,
};

export default ButtonSubmit;
