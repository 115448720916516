import React from 'react';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import { StaticQuery, graphql } from 'gatsby';
import head from 'lodash.head';
import CookieConsentStyles from './cookie-consent.module.scss';
import { ButtonLink } from '../button';

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitialized: true,
      showBanner: true,
    };

    this.cookieBannerID = 'cookieConsentClosed';
  }

  destroyCookieBanner = () => {
    this.setState({
      showBanner: false,
    });
  };

  hideCookieBanner = () => {
    if (this.cookieBannerID) {
      this.setCookieValue(this.cookieBannerID);
    }
    setTimeout(() => this.destroyCookieBanner(), 400);
  };

  initCookieBanner = () => {
    this.cookieBanner.classList.add('is-initialized');
    this.cookieBanner.classList.remove('is-hidden');
  };

  addAcceptCookie = () => {
    this.hideCookieBanner();
    window.localStorage.setItem('cookiesAllowed', JSON.stringify({ suppressed: true }));
    this.injectAnalytics();
  };

  addRejectCookie = () => {
    this.hideCookieBanner();
    window.localStorage.setItem('cookiesRejected', JSON.stringify({ suppressed: true }));
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * 1000);
    /* eslint-disable no-useless-concat */
    document.cookie = `${'__hs_opt_out' + '=' + 'yes' + ';path=/;expires='}${d.toGMTString()}`;
    /* eslint-disable no-useless-concat */
  };

  /* eslint-disable consistent-return */
  setCookieValue = id => {
    if (typeof window !== 'undefined') {
      return window.localStorage.setItem(id, JSON.stringify({ suppressed: true }));
    }
  };

  getCookieValue = id => {
    if (typeof window !== 'undefined') {
      return window.localStorage.getItem(id);
    }
  };
  /* eslint-disable consistent-return */

  injectAnalytics = () => {
    ReactGA.initialize('UA-131771879-1');
    /* eslint-disable no-restricted-globals */
    ReactGA.pageview(location.pathname);
    /* eslint-disable no-restricted-globals */
  };

  testForHideOnLoad = () => {
    if (this.getCookieValue(this.cookieBannerID)) {
      this.destroyCookieBanner();
    } else {
      this.initCookieBanner();
    }
  };

  init = () => {
    if (this.cookieBannerID) {
      this.testForHideOnLoad();
    } else {
      this.initCookieBanner();
    }
  };

  componentDidMount() {
    this.cookieBanner = document.querySelector('#cookie-banner');
    /* eslint-disable react/no-did-mount-set-state */
    this.setState({
      isInitialized: true,
    });
    /* eslint-disable react/no-did-mount-set-state */

    this.init();
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query tracking {
            allContentfulTracking {
              edges {
                node {
                  cookieBannerHeadline
                  cookieBannerText
                  cookieBannerAcceptText
                  cookieBannerAcceptTextColor
                  cookieAcceptBackgroundColor
                  cookieBannerRejectText
                  cookieBannerRejectTextColor
                  cookieRejectBackgroundColor
                  privacyPolicyUrl
                }
              }
            }
          }
        `}
        render={({ allContentfulTracking: { edges } }) => {
          const {
            cookieBannerHeadline,
            cookieBannerText,
            cookieBannerAcceptText,
            cookieBannerAcceptTextColor,
            cookieAcceptBackgroundColor,
            cookieBannerRejectText,
            cookieBannerRejectTextColor,
            cookieRejectBackgroundColor,
            privacyPolicyUrl,
          } = head(edges).node;

          const wrapperClass = classNames(CookieConsentStyles.wrapper, {
            [CookieConsentStyles.isHidden]: !this.state.isInitialized,
          });

          return (
            <div className={wrapperClass} id="cookie-banner">
              {this.state.showBanner && (
                <div className={CookieConsentStyles.container}>
                  <div className={CookieConsentStyles.text}>
                    <h2 className={CookieConsentStyles.headline}>{cookieBannerHeadline}</h2>
                    <p className={CookieConsentStyles.paragraph}>
                      {cookieBannerText} <a href={privacyPolicyUrl}>Privacy Policy</a>
                    </p>
                  </div>
                  <div className={CookieConsentStyles.actions}>
                    <div className={CookieConsentStyles.button}>
                      <ButtonLink
                        color={cookieBannerAcceptTextColor}
                        backgroundColor={cookieAcceptBackgroundColor}
                        onClick={this.addAcceptCookie}
                      >
                        {cookieBannerAcceptText}
                      </ButtonLink>
                    </div>
                    <ButtonLink
                      color={cookieBannerRejectTextColor}
                      backgroundColor={cookieRejectBackgroundColor}
                      onClick={this.addRejectCookie}
                    >
                      {cookieBannerRejectText}
                    </ButtonLink>
                  </div>
                </div>
              )}
            </div>
          );
        }}
      />
    );
  }
}

export default CookieConsent;
