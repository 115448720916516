import React from 'react';
import PropTypes from 'prop-types';
import { ButtonLink } from '../../button';

const ExternalNavLink = ({ className, linkColor, id, externalUrl, displayName }) => (
  <span key={id} className={className}>
    <ButtonLink type="navbar" href={externalUrl} color={linkColor}>
      {displayName}
    </ButtonLink>
  </span>
);

ExternalNavLink.propTypes = {
  className: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  externalUrl: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
};

export default ExternalNavLink;
