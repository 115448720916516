import PropTypes from 'prop-types';

export default PropTypes.shape({
  signUpButton: PropTypes.shape({
    text: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    url: PropTypes.string,
  }),
  backgroundColor: PropTypes.string,
  headline: PropTypes.string,
  headlineColor: PropTypes.string,
  panelHeadline: PropTypes.string,
  panelHeadlineColor: PropTypes.string,
  textBackgroundColor: PropTypes.string,
  imageBackgroundColor: PropTypes.string,
  discounttrialBadge: PropTypes.string,
  discounttrialBadgeTextColor: PropTypes.string,
  discounttrialBadgeBackgroundColor: PropTypes.string,
  imageAltText: PropTypes.string,
  image: PropTypes.shape({
    id: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string,
      fileName: PropTypes.string,
      contentType: PropTypes.string,
    }),
  }),
  bulletPointList: PropTypes.shape({
    id: PropTypes.string,
    bulletPoint: PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      textColor: PropTypes.string,
      imageAltText: PropTypes.string,
      image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
          fileName: PropTypes.string,
          contentType: PropTypes.string,
        }),
      }),
    }),
  }),
});
