import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string,
  backgroundColor: PropTypes.string,
  imageAltText: PropTypes.string,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
      fileName: PropTypes.string,
      contentType: PropTypes.string,
    }),
  }),
});
