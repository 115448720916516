import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { pageLinkPropType } from '../../../prop-types';
import sidemenuStyles from './sidemenu.module.scss';

const SideMenu = ({ pages, linkColor, isActive }) => (
  <nav
    className={classnames(sidemenuStyles.wrapper, {
      [`${sidemenuStyles.wrapperIsActive}`]: isActive,
    })}
    aria-hidden={!isActive}
    id="side-menu"
  >
    <ul className={sidemenuStyles.list}>
      {pages.map(({ displayName, slug, id }) => (
        <li key={id} className={sidemenuStyles.listItem}>
          <Link to={slug} className={sidemenuStyles.link} style={{ color: linkColor }}>
            {displayName}
          </Link>
        </li>
      ))}
    </ul>
  </nav>
);

SideMenu.propTypes = {
  pages: PropTypes.arrayOf(pageLinkPropType).isRequired,
  isActive: PropTypes.bool.isRequired,
  linkColor: PropTypes.string.isRequired,
};

export default SideMenu;
