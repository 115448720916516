import PropTypes from 'prop-types';

export default PropTypes.shape({
  backgroundColor: PropTypes.string,
  headline: PropTypes.string,
  headlineTextColor: PropTypes.string,
  teaser: PropTypes.shape({
    teaser: PropTypes.string,
  }).isRequired,
  teaserColor: PropTypes.string,
  heroImageAltText: PropTypes.string,
  heroImage: PropTypes.shape({
    id: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string,
      fileName: PropTypes.string,
      contentType: PropTypes.string,
    }),
  }),
  heroButton: PropTypes.shape({
    text: PropTypes.string,
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    url: PropTypes.string,
  }),
});
