import PropTypes from 'prop-types';
import { logoBoxPropType } from './';

export default PropTypes.shape({
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  company: PropTypes.string,
  position: PropTypes.string,
  person: PropTypes.string,
  quoteText: PropTypes.shape({
    quoteText: PropTypes.string,
  }),
  textLeftlogoBoxRight: PropTypes.bool,
  logoBox: logoBoxPropType,
});
