import PropTypes from 'prop-types';

export default PropTypes.shape({
  title: PropTypes.string,
  titleColor: PropTypes.string,
  headline: PropTypes.string,
  headlineColor: PropTypes.string,
  image: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
      fileName: PropTypes.string,
      contentType: PropTypes.string,
    }),
  }),
  imageBackgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.shape({
    text: PropTypes.string,
  }),
});
