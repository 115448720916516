import PropTypes from 'prop-types';
import { highlightPanelPropType } from './';

export default PropTypes.shape({
  collageBackgroundColor: PropTypes.string,
  block1ImageAltText: PropTypes.string,
  block1ImageBackgroundColor: PropTypes.string,
  block1Image: PropTypes.shape({
    id: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string,
      fileName: PropTypes.string,
      contentType: PropTypes.string,
    }),
  }),
  block2Headline: PropTypes.string,
  block2HeadlineColor: PropTypes.string,
  block2Text: PropTypes.string,
  block2TextColor: PropTypes.string,
  block2BackgroundColor: PropTypes.string,
  block3HighlightPanel: highlightPanelPropType,
  block4Headline: PropTypes.string,
  block4TextColor: PropTypes.string,
  block4ImageAltText: PropTypes.string,
  block4BackgroundColor: PropTypes.string,
  block4Image: PropTypes.shape({
    id: PropTypes.string,
    file: PropTypes.shape({
      url: PropTypes.string,
      fileName: PropTypes.string,
      contentType: PropTypes.string,
    }),
  }),
  block5Headline: PropTypes.string,
  block5TextColor: PropTypes.string,
  block5BackgroundColor: PropTypes.string,
  block6HighlightPanel: highlightPanelPropType,
});
