import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import head from 'lodash.head';
import { pageLinkPropType } from '../../prop-types';
import Section from '../section';
import ExternalNavLink from './external-nav-link';
import Hamburger from './hamburger';
import mainHeaderStyles from './main-header.module.scss';

const MainHeader = ({
  pages,
  backgroundColor,
  linkColor,
  logo,
  logoAltText,
  onToggle,
  isActive,
}) => {
  const externalLink = head(pages.filter(page => page.isMainCta));

  return (
    <Section backgroundColor={backgroundColor}>
      <div className={mainHeaderStyles.container}>
        <Link to="/">
          <img className={mainHeaderStyles.logo} src={logo.file.url} alt={logoAltText} />
        </Link>

        <div className={mainHeaderStyles.smallViewportCallToActionGroup}>
          {externalLink && (
            <ExternalNavLink
              className={mainHeaderStyles.externalLinkSmallViewport}
              linkColor={linkColor}
              {...externalLink}
            />
          )}
          <Hamburger onClick={onToggle} isActive={isActive} backgroundColor={linkColor} />
        </div>
        {
          // we map over the entire list, including the mainCta,
          // despite having filtered it out above so that the link order in the CMS is respected
        }
        <nav className={mainHeaderStyles.navigation}>
          {pages.map(({ displayName, slug, id, isMainCta }) =>
            isMainCta ? (
              <ExternalNavLink
                key={id}
                className={mainHeaderStyles.externalLink}
                linkColor={linkColor}
                {...externalLink}
              />
            ) : (
              <Link
                to={slug}
                key={id}
                className={mainHeaderStyles.internalLink}
                style={{ color: linkColor }}
              >
                {displayName}
              </Link>
            )
          )}
        </nav>
      </div>
    </Section>
  );
};

MainHeader.propTypes = {
  pages: PropTypes.arrayOf(pageLinkPropType).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  linkColor: PropTypes.string.isRequired,
  logo: PropTypes.shape({
    file: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  logoAltText: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default MainHeader;
